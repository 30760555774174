import { Component, OnInit } from '@angular/core';

import * as S3 from "aws-sdk/clients/s3";
import { UserSandbox } from '../../user.sandbox';
import { environment } from '../../../../environments/environment';


@Component({
  selector: 'app-my-files',
  templateUrl: './my-files.component.html',
  styleUrls: ['./my-files.component.css']
})
export class MyFilesComponent implements OnInit {

  private files;
  filesToShow = [];
  private bucket: S3;
  selectedFile;
  private projectName;
  loading;
  private keyToDelete;
  videoUrl;
  timelapseShown = false;

  constructor(private userSandbox: UserSandbox) { 
    
  }

  ngOnInit() {
    this.init();
  }

  init() {
    this.bucket = new S3(
      {
          accessKeyId: environment.awsAccessKeyId,
          secretAccessKey: environment.awsSecretAccessKey,
          region: 'eu-central-1'
      }
    );
    this.userSandbox.getSelectedProject().subscribe((project) => {
      this.projectName = project.folder;
      const params = {
        Bucket: 'buildevolution-myfiles',
        Prefix: project.folder + '/'
      };
      this.bucket.listObjects(params, (err, data) => {
        console.log("Error: ", err);
        console.log("Data: ", data);
        this.files = data.Contents.filter(file => {
          return file.Key != this.projectName + '/'
        }).map(file => {
          file.Key = file.Key.replace(this.projectName + '/', '');
          return file;
        });
        this.filesToShow = this.files.filter(file => !file.Key.includes('/'));
        this.loading = false;
        this.selectedFile = false;
      })
    })
  }

  showFolderContent(folder) {
    this.filesToShow = this.files.filter(file => file.Key.includes(folder + '/'));
    this.timelapseShown = true;
  }

  hideFolderContent() {
    this.filesToShow = this.files.filter(file => !file.Key.includes('/'));
    this.timelapseShown = false;
  }


  async playVideo(key) {
    this.videoUrl = await this.bucket.getSignedUrl('getObject', {Bucket: "buildevolution-myfiles", Key: this.projectName + '/' + key, Expires: 60*5})
  }

  closeVideo() {
    this.videoUrl = null;
  }

  async downloadItem(key) {
    const itemURL = await this.bucket.getSignedUrl('getObject', {Bucket: "buildevolution-myfiles", Key: this.projectName + '/' + key, Expires: 60*5})
    const link = document.createElement('a')
    link.href = itemURL
    link.target= "_blank";
    link.download = 'buildevolution-file'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  upload() {
    this.loading = true;
    const file = this.selectedFile;
    const params = {
      Bucket: 'buildevolution-myfiles',
      Key: this.projectName + '/' + file.name,
      Body: file,
      ContentType: file.type
    }
    
    this.bucket.upload(params, (err, data) => {
      if (err) {
          console.log('There was an error uploading your file: ', err);
          return false;
      }
      this.init();
      return true;
    });
  }


  confirmDelete(key) {
    this.keyToDelete = key;
  }

  resetConfirmDelete() {
    this.keyToDelete = undefined;
  }

  deleteItem(key) {
    this.loading = true;
    this.bucket.deleteObject({Bucket: "buildevolution-myfiles", Key: this.projectName + "/" + key}, (err, response) => {
      this.keyToDelete = undefined;
      this.init();
    })
  }
    
  selectFile(event) {
    this.selectedFile = event.target.files.item(0);
  }

  getFileName(key) {
    return key.replace('timelapse/', '');
  }

  getFileSize(size) {
    let finalSize;
    let suffix;
    if (size > 1000000) {
      finalSize = size / 1000000;
      suffix = "Mo";
    } else if (size > 1000) {
      finalSize = size / 1000;
      suffix = "Ko";
    } else {
      finalSize = size;
      suffix = "O"
    }
    finalSize =  Math.round(finalSize * 100) / 100;
    return finalSize + " " + suffix;
  }

  getFileDate(date) {
    return new Date(date).toLocaleString();
  }
}
