/* tslint:disable */
import { Injectable } from '@angular/core';

/**
 * Contains global configuration for API services
 */
@Injectable()
export class ApiConfiguration {
  //rootUrl: string = "http://localhost:8080"
  //rootUrl: string = "https://app.buildevolution.be";
  rootUrl: string = window.location.origin;
}
