import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import {Media} from '../../../api/models/media';
import * as S3 from "aws-sdk/clients/s3";


@Component({
  selector: 'app-media-show',
  templateUrl: './media-show.component.html',
  styleUrls: ['./media-show.component.css']
})
export class MediaShowComponent implements OnInit {

  @Input() url: string;
  @Input() media: Media;
  @Input() folders: string[];
  @Input() selectedFolder: string;
  @Output() folderChanged: EventEmitter<string> = new EventEmitter();
  defaultFolder: string;
  private bucket: S3;
  fileUrl;
  constructor(private sanitizer: DomSanitizer) { }

  ngOnInit() {
    this.defaultFolder = this.selectedFolder;
  }


  async downloadImage() {
    const itemURL = await this.bucket.getSignedUrl('getObject', {Bucket: "buildevolution", Key: this.media.project + '/' + this.media.folder + '/' + this.media.name, Expires: 60*5})
    const image = await fetch(itemURL)
    const imageBlog = await image.blob()
    const imageURL = URL.createObjectURL(imageBlog)
    const link = document.createElement('a')
    link.href = imageURL
    link.download = 'buildevolution-image'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)

  }

  changeFolder() {
    this.folderChanged.emit(this.defaultFolder);
  }

  getHoure(): string {
    return this.media.name.slice(8, 10) + ':' + this.media.name.slice(10, 12);
  }

  getDate(): string {
    return this.media.name.slice(0, 4) + '-' + this.media.name.slice(4, 6) + '-' + this.media.name.slice(6, 8);
  }
}
