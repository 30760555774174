// angular module
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {FormsModule} from '@angular/forms';
import {HTTP_INTERCEPTORS, HttpClientJsonpModule, HttpClientModule} from '@angular/common/http';



// other module
import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
import { PopoverModule } from 'ngx-bootstrap/popover';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {ImageZoomModule} from 'angular2-image-zoom';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from 'ngx-google-analytics';
// store module
import { StoreModule } from '@ngrx/store';
import { reducers } from './store/index';
import {NgbTimepickerModule} from '@ng-bootstrap/ng-bootstrap'
// route module
import {AppRoutingModule} from './app-routing.module';

/* api module
* when module is re-generated
* node_modules/.bin/ng-swagger-gen -i http://localhost:56285/swagger/v1/swagger.json -o .\src\app\api\
* on api-configuration.ts :
* 1) change rootUrl: string = 'URL';
* on all services: (to use http response's body on POST, PUT, DELETE and GET{id} requests)
* 1) replace 'void = null' by 'any = _resp.body'
* 2) replace 'void' by 'any'
* 3) replace ' 'text' ' by ' 'json' '
* 4) add ' {'Content-Type': 'application/json'} ' in aws postConfigFile to the headers
*/
import {ApiModule} from './api/api.module';
import {AuthModule} from './auth/auth.module';

// provider
import { NavbarSandbox } from './navbar.sandbox';
// components
import { AppComponent } from './app.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { NavbarPageComponent } from './containers/navbar-page/navbar-page.component';
import {AuthInterceptor} from './utilities/auth.interceptor';
import {AuthService} from './utilities/auth.service';
import {AuthGuard} from './utilities/auth.guard';
import {AdminGuard} from './utilities/admin.guard';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { FooterComponent } from './components/footer/footer.component';

@NgModule({
  declarations: [
    AppComponent,
    NavbarPageComponent,
    NavbarComponent,
    FooterComponent
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    FormsModule,
    HttpClientModule,
    HttpClientJsonpModule,
    NgbModule,
    ShareButtonsModule,
    PopoverModule.forRoot(),
    ApiModule,
    AuthModule,
    StoreModule.forRoot(reducers),
    ImageZoomModule,
    NgScrollbarModule,
    BrowserAnimationsModule,
    NgxGoogleAnalyticsModule.forRoot('G-TZBCVX712H'),
    NgxGoogleAnalyticsRouterModule,
    NgbTimepickerModule
  ],
  providers: [
    NavbarSandbox,
    AuthService,
    AuthGuard,
    AdminGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
